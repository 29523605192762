import React from 'react';
import {
  TextInput,
  FormItem,
  Form,
  FormGroup,
  Modal,
  Loading,
  Checkbox,
} from 'carbon-components-react';
import CustomButton from '../../custom-button/custom-btn.component';
import { createClass } from '../../../api/class.service';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../../redux/selectors/user.selectors';
import { setClasses } from '../../../redux/actions/class.actions';
import { connect } from 'react-redux';

import './add-class.styles.scss';

class AddClassModal extends React.Component {
  constructor(props) {
    super(props);

    // set state
    this.state = {
      openModal: props.openModal,
      classToEdit: props.class,
      id: 0,
      description: '',
      semester: false,
      isPreSchool: false,
      loading: false,
      message: null,
      messageClass: '',
      btnText: 'SUBMIT',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.openModal !== prevState.openModal) {
      return { openModal: nextProps.openModal };
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.class !== prevProps.class) {
      if (this.props.class) {
        this.setState({
          id: this.props.class.id,
          classToEdit: this.props.class,
          description: this.props.class.description,
          semester: this.props.class.semester,
          isPreSchool: this.props.class.isPreSchool,
          message: null,
          messageClass: '',
          btnText: 'UPDATE',
        });
      } else {
        this.setState({
          id: 0,
          classToEdit: null,
          description: '',
          semester: false,
          isPreSchool: false,
          message: null,
          messageClass: '',
          btnText: 'ADD',
        });
      }
    }
  }

  onCreateClassBtnClicked = async (event) => {
    event.preventDefault();

    // update state to set request in progress to true and error occurred to false
    this.setState({
      loading: true,
      message: null,
      btnText: null,
    });

    // build data
    const data = {
      id: this.state.id,
      description: this.state.description,
      semester: this.state.semester,
      isPreSchool: this.state.isPreSchool,
    };

    // initiate request
    const response = await createClass(this.props.currentUser.authToken, data);

    if (!response.success) {
      this.setState({
        loading: false,
        message: response.message,
        messageClass: 'text-danger',
        btnText: 'SUBMIT',
      });

      return;
    }

    // on success
    this.setState({
      loading: false,
      description: '',
      semester: false,
      isPreSchool: false,
      message: `${response.message} Add more or close this dialog.`,
      messageClass: 'text-success',
      btnText: 'SUBMIT',
    });

    // close dialog if it was an update
    if (this.state.classToEdit) {
      this.props.cancelAction();
    }

    // update redux
    this.props.setClasses([response.class]);
  };

  render() {
    return (
      <Modal
        id="add-class-modal"
        className="add-class-modal"
        hasScrollingContent
        passiveModal={true}
        open={this.state.openModal}
        modalHeading={this.state.classToEdit ? 'Edit Class' : 'Add New Class'}
        primaryButtonDisabled={false}>
        <div>
          <Form action="post">
            <FormGroup legendText="">
              <FormItem>
                <TextInput
                  id="description"
                  name="description"
                  labelText="Description"
                  value={this.state.description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  placeholder="E.g. Grade 1 or Class 1"
                />
              </FormItem>
            </FormGroup>

            <FormGroup legendText="" hidden={this.state.classToEdit}>
              <FormItem style={{ marginTop: '0' }}>
                <Checkbox
                  id="semester"
                  name="semester"
                  labelText="Semester ?"
                  checked={this.state.semester}
                  onChange={(value) => this.setState({ semester: value })}
                  placeholder="E.g. Grade 1 or Class 1"
                />
              </FormItem>
            </FormGroup>

            <FormGroup legendText="">
              <FormItem style={{ marginTop: '0' }}>
                <Checkbox
                  id="isPreSchool"
                  name="isPreSchool"
                  labelText="PreSchool ?"
                  checked={this.state.isPreSchool}
                  onChange={(value) => this.setState({ isPreSchool: value })}
                  placeholder=""
                />
              </FormItem>
            </FormGroup>

            <p
              className="text-grey text-14"
              hidden={this.state.classToEdit !== null}>
              Note: Classes are to be created in ascending order. E.g. Nursery 1
              to JHS 3.
            </p>

            <CustomButton
              classes="primary f-width mt-20 font-bold"
              disabled={
                this.state.description.length === 0 || this.state.loading
              }
              onClick={this.onCreateClassBtnClicked}>
              {this.state.btnText}

              <div hidden={this.state.btnText != null}>
                <div className="d-flex-center">
                  <Loading
                    withOverlay={false}
                    small={true}
                    description="Creating class..."
                  />
                </div>
              </div>
            </CustomButton>

            <p
              className={`text-14 mt-10 ${this.state.messageClass}`}
              hidden={this.state.message === null}>
              {this.state.message}
            </p>

            <CustomButton
              classes="outline danger f-width font-bold mt-20"
              onClick={this.props.cancelAction}>
              CLOSE
            </CustomButton>
          </Form>
        </div>
      </Modal>
    );
  }
}

// this will provide us with the state values we need
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

// this allows us to map our redux actions to our component
const mapDispatchToProps = (dispatch) => ({
  setClasses: (classes) => dispatch(setClasses(classes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClassModal);
